<template>
  <div class="record">
    <div class="record_list_box">
      <div class="record_list">
        <div class="school_title">
          熊苑教育老师示例
        </div>
        <div class="title">
          天风城
        </div>
        <div class="record_item_box" v-for="(item, index) in recordList" :key="index">
          <span class="name">
            {{item.name}}
          </span>
          <audio :src="item.fileUrl" preload="none" controls></audio>
        </div>
      </div>
    </div>
    <div class="operation_box">
      <div class="operation">
        <div class="item_box all_box">
          <div class="btn show_all" @click="showAll"><i class="iconfont iconliebiao"></i></div>
        </div>
        <div class="item_box btn_box">
          <div class="btn" v-show="audioState === 'inactive'" @click="record"><i class="iconfont iconluyin"></i></div>
          <div class="btn" v-show="audioState === 'recording'" @click="pauseRecord"><i class="iconfont iconzantingtingzhi"></i></div>
          <div class="btn" v-show="audioState === 'paused'" @click="resume"><i class="iconfont iconluyin"></i></div>
        </div>
        <div class="item_box stop_box">
          <div class="btn stop" v-show="audioState !== 'inactive'" @click="stopRecord"><i class="iconfont iconjieshu-"></i></div>
        </div>
      </div>
      <audio v-show="audioUrl" id="currentAudio" ref="currentAudio" :src="audioUrl" width="400" height="600" preload="none" controls></audio>
    </div>
    <pop
      ref="uploadBox"
      :forInput=true
      :fileName.sync="fileName"
      @sure="uploadRecorder"
      @cancel="cancel" />
  </div>
</template>

<script>
  import {uploadRec} from '@/vue_api/upload.js'
  import pop from '@/component/pop/pop.vue'
  export default {
    name: 'recorderForC34',
    components: {
      pop
    },
    data() {
      return {
        audioStream: {},
        audioUrl: '',
        audioState: '',
        fileName: '',
        fileBlob: {},
        recordList: [
          {
            name: '婉婉熊',
            fileUrl: 'https://zyclen.cn/recorder/熊苑教育/婉婉熊-天风城.mp3'
          },{
            name: '5.14沧海熊',
            fileUrl: 'https://zyclen.cn/recorder/熊苑教育/沧海熊-天风城.mp3'
          },{
            name: '5.22朗朗熊',
            fileUrl: 'https://zyclen.cn/recorder/熊苑教育/朗朗熊-天风城.mp3'
          },
        ]
      }
    },
    mounted() {
      this.createMedia()
    },
    methods: {
      // 显示全部录音
      showAll() {
        console.log('显示全部录音----');
      },
      // 弹框确定，上传录音
      async uploadRecorder() {
        let formData = new FormData();
        formData.append('file', this.fileBlob, this.fileName)
        console.log('即将上传录音====', formData, this.fileBlob, this.fileName)
        await uploadRec(formData).then(res =>{
          console.log('上传录音完成====', res.data)
          if(res && res.data && res.data.status) {
            this.$nextTick(() =>{
              // this.params.logoUrl = res.data.url
              // this.params.imgName = res.data.name
              console.log('上传成功~~', res.data)
            })
          } else {
            console.log('上传失败')
          }
        }).catch(err => {
          console.log('上传失败了====', err)
        })
        // this.$refs.uploadBox.close()
      },
      cancel() {
        // this.$refs.uploadBox.close()
      },
      // 创建媒体流
      createMedia() {
        const _this = this
        const constraints = { audio: true, video: false }
        console.log('navigator有没有-----', navigator, navigator.mediaDevices)
        if (navigator.mediaDevices) {
          navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
              /* 使用这个stream stream */
              console.log('流媒体相关参数------', stream)
              _this.audioStream = new MediaRecorder(stream, {
                audioBitsPerSecond: 128000,
                // audioBitsPerSecond: 128000,
                MimeType: 'audio/mp3'
              })
              _this.audioState = _this.audioStream.state
              console.log('流媒体当前状态-----000', _this.audioStream.state)

            })
            .catch(function (err) {
              /* 处理error */
              _this.finalMes = '录音失败' + err.toString()
              _this.$refs.goDateBox.open()
              console.log('录音失败-----', err)
            });
        } else {
          console.log('您的设备不支持录音~')
        }
      },
      // 暂停录音
      pauseRecord() {
        this.audioStream.pause()
        this.audioState = this.audioStream.state
        console.log('流媒体当前状态-----222', this.audioStream.state)
      },
      // 继续录音
      resume() {
        this.audioStream.resume()
        this.audioState = this.audioStream.state
        console.log('流媒体当前状态-----333', this.audioStream.state)
      },
      // 结束录音
      stopRecord() {
        this.fileBlob = {}
        this.audioStream.stop()
        this.$nextTick(() => {
          this.fileName = '录音文件'
        })
        this.audioState = this.audioStream.state
        console.log('流媒体当前状态-----444', this.audioStream.state, this.fileName)
        this.audioStream.ondataavailable = (event) => {
          this.$nextTick(() => {
            this.audioUrl = URL.createObjectURL(event.data);
          })
          console.log('event----', event)
          if(event.data.size) {
            this.fileBlob = event.data
            // this.$refs.uploadBox.open()
          }
        }
      },
      // 点我录音
      record() {
        this.audioUrl = ''
        this.audioStream.start()
        this.audioState = this.audioStream.state
        console.log('流媒体当前状态-----111', this.audioStream.state)
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import './index.scss'
</style>